import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withScope, captureException } from '@sentry/react';
import { Notifications, ErrorBoundary as ErrorBoundaryProvider } from 'c2-common-ui';
import { ERROR } from '@/constants/appVariables';

const reportError = (error, errorInfo) => {
  withScope((scope) => {
    if (errorInfo) {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    }
    scope.setLevel('error');
    captureException(error);
  });
};

export const ErrorBoundary = ({ children }) => {
  const location = useLocation();
  const [errorLocation, setErrorLocation] = useState(null);
  const [remountKey, setRemountKey] = useState(0);

  useEffect(() => {
    if (errorLocation && errorLocation !== location.pathname) {
      setRemountKey((prevKey) => prevKey + 1);
      setErrorLocation(null);
    }
  }, [errorLocation, location.pathname]);

  const handleError = (error, errorInfo) => {
    reportError(error, errorInfo);
    setErrorLocation(location.pathname);
  };

  return (
    <ErrorBoundaryProvider key={remountKey} onError={handleError}>
      <Notifications.NotificationFactory />
      {children}
    </ErrorBoundaryProvider>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export const createNotification = ({
  type,
  text = '',
  description = '',
  isModal = false,
  onClose = () => {},
}) => {
  const { addNotification, NOTIFICATION_TYPES } = Notifications;

  addNotification({ type: NOTIFICATION_TYPES[type], text, description, isModal, onClose });

  if (type === ERROR) {
    reportError(new Error(text), { description });
  }
};
