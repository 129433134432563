import { UserInfoService, PermissionsRule } from 'c2-common-ui';
import { ROLES } from '@/constants/appVariables';

let userInfoInstance: UserInfoService | null = null;
const defaultPermissionsRules: PermissionsRule[] = [];

export const initUserInfo = (permissionsRules: PermissionsRule[] = defaultPermissionsRules) => {
  if (userInfoInstance) {
    throw new Error('UserInfoService has already been initialized.');
  }
  userInfoInstance = UserInfoService.getInstance(permissionsRules);
};

export const getUserInfo = (): UserInfoService => {
  if (!userInfoInstance) {
    throw new Error('UserInfoService has not been initialized. Call initUserInfo first.');
  }
  return userInfoInstance;
};

export const isUserAM = () => getUserInfo().permissions.hasRole(ROLES.AM_REGULAR_USER);
export const isUserPM = () => getUserInfo().permissions.hasRole(ROLES.PM_REGULAR_USER);
export const isUserXM = () => getUserInfo().permissions.hasRole(ROLES.XM_REGULAR_USER);
